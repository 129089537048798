@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600,700');

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';

$color_red: #cf0000;

body,
h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.home-bg {
    background-image: url('../images/bg-home.jpg');
    @include media-breakpoint-down(sm) {
        padding-top: 160px;
    }
}

.bg-cta {
    background-image: url('../images/bg-cta.jpg');
}

.bg-client {
    background-image: url('../images/bg-clients.jpg');
}

.header_title {
    max-width: 300px;

    @include media-breakpoint-up(sm) {
        max-width: 600px;
    }
}

.back_top {
    left: 20px;
    right: initial;
    bottom: 35px;
}

.scroll_down {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

/* NAVBAR */

.custom-nav {
    @include media-breakpoint-down(md) {
        background-color: transparent !important;
    }

    .navbar-brand {
        transition: opacity .5s ease;

        @include media-breakpoint-down(md) {
            opacity: 0;
        }
    }

    .navbar-toggler {
        color: #fff;
    }

    .navbar-collapse {
        transition: margin-top .25s ease;

        @include media-breakpoint-down(md) {
            margin-top: -38px;
        }
    }

    &.opened {
        background-color: #000 !important;
    }

    &.stickyadd {
        background-color: #000 !important;

        .navbar-brand {
            opacity: 1;
        }

        .navbar-collapse {
            margin-top: 0;
        }
    }
}

/* COLORS */
a,
.text-custom {
    color: $color_red !important;
}

.custom-nav .navbar-nav li a {
    color: rgba(255, 255, 255, 0.5) !important;
}

.custom-nav.stickyadd .navbar-nav li a {
    color: rgba(255,255,255,0.5) !important;
}

.skill-custom .progress-bar {
    background: $color_red !important;
}

.custom-nav.stickyadd .navbar-nav li.active a, .custom-nav.stickyadd .navbar-nav li a:hover, .custom-nav.stickyadd .navbar-nav li a:active {
    color: #fff !important;
}

.fot_social .social-icon:hover {
    border-color: $color_red !important;
    color: $color_red !important;
}

.back_top {
    background-color: $color_red !important;
}

.btn-outline-custom {
    color: #fff !important;
}
